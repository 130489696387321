@import url(https://fonts.googleapis.com/css?family=Montserrat);
html,
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: "Montserrat" !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: "100%";
  background-color: #ffffff;
  /* background-color: #f6f9fc; */
}

html{
  scroll-behavior: smooth;
}

code {
  /* font-family: "Montserrat"; */
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  height: "30px";
}

.App {
  text-align: center;
  background-color: #ffffff;
  /* background-color: #f6f9fc; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1.typed-text {
  /* font-weight: bold; */
  /* color: #747fe0; */
  /* font-family: "Montserrat"; */
  font-size: "64px" !important;
  color: #000000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: "0px 0px 0px 0px";
}
h1.cursor {
  display: inline-block;
  /* background-color: #747fe0; */
  /* color: #747fe0; */
  margin-left: 0.1rem;
  width: 3px;
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}
h1.cursor.typing {
  -webkit-animation: none;
          animation: none;
}
@-webkit-keyframes blink {
  0% {
    background-color: #ccc;
  }
  49% {
    background-color: #ccc;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #ccc;
  }
}
@keyframes blink {
  0% {
    background-color: #ccc;
  }
  49% {
    background-color: #ccc;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #ccc;
  }
}

