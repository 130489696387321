@import url("https://fonts.googleapis.com/css?family=Montserrat");

h1.typed-text {
  /* font-weight: bold; */
  /* color: #747fe0; */
  /* font-family: "Montserrat"; */
  font-size: "64px" !important;
  color: #000000;
  width: fit-content;
  margin: "0px 0px 0px 0px";
}
h1.cursor {
  display: inline-block;
  /* background-color: #747fe0; */
  /* color: #747fe0; */
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}
h1.cursor.typing {
  animation: none;
}
@keyframes blink {
  0% {
    background-color: #ccc;
  }
  49% {
    background-color: #ccc;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #ccc;
  }
}
